import React from 'react'
import PropTypes from 'prop-types'
import { FullWidthImageContainer, FullWidthImageStyled } from './styles'

const NetZeroFullWidthImage = ({ query }) => {
  return (
    <>
      {query?.items?.length > 0 &&
        query?.items.map((image, index) => {
          return (
            <FullWidthImageContainer key={index}>
              <FullWidthImageStyled
                fluid={
                  image?.fullwidth_image?.localFile?.childImageSharp?.fluid
                }
                alt={image?.fullwidth_image?.alt}
              />
            </FullWidthImageContainer>
          )
        })}
    </>
  )
}

NetZeroFullWidthImage.propTypes = {
  query: PropTypes.object.isRequired
}

export default NetZeroFullWidthImage
