import styled from '@emotion/styled'
import Img from 'gatsby-image'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'

export const IconContainerMain = styled.div`
  max-width: calc(100% - 20px);
  margin: 100px 20px 40px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    width: calc(100% - 100px);
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 150px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    width: calc(100% - 200px);
    margin-left: 100px;
    margin-right: 100px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    width: calc(100% - 376px);
    margin-left: 186px;
    margin-right: 186px;
  }
`

export const IconImage = styled(Img)`
  width: 148px;
  height: 148px;
  margin-bottom: 20px;
`

export const FullWidthGraphics = styled(Img)`
  width: 100%;
  overflow: visible;
`

export const IconsContainerGridItem = styled.div`
  width: calc(100% - 20px);
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
    white-space: pre-wrap;
  }
`

export const IconTitleStyled = styled(Title)`
  margin-bottom: 20px;
`

export const IconBodyStyled = styled(Text)`
  font-weight: 200;
`
export const IconContent = styled.div`


@media (min-width: ${props => props.theme.breakpoints.md}) {
  max-width: 300px;
  padding-left: 50px;
}

`


