import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import Img from 'gatsby-image'
import StyledLink from 'components/Utilities/StyledLink'

export const BodyContentContainer = styled.div`
  width: calc(100% - 40px);
  margin-right: 20px;
  margin-left: 20px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - 100px);
    margin-left: 50px;
    margin-right: 50px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    width: calc(100% - 200px);
    margin-left: 100px;
    margin-right: 100px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    width: calc(100% - 376px);
    margin-left: 186px;
    margin-right: 186px;
  }
`

export const MainContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    align-items: flex-start;
    flex-direction: ${props => (props.alignImageLeft ? 'row-reverse' : 'row')};
  }
`

export const PrimaryContainer = styled.div`
  width: calc(100% - 40px);
  margin-bottom: 70px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 50%;
    margin-top: 150px;
    margin-bottom: 150px;
  }
`

export const StyledTitle = styled(Title)`
  margin-bottom: 40px;
  font-weight: 500;
`

export const StyledBody = styled(Text)`
  font-weight: 200;
`

export const SecondaryContainer = styled.div`
  width: 100%;
  margin-top: 70px;
  margin-bottom: 70px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 50%;
    margin-top: 150px;
    margin-bottom: 150px;
  }
`

export const StyledImageContent = styled(Img)`
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 350px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    max-width: 500px;
  }
`
export const CtaStyled = styled(StyledLink)`
  padding-top: 40px;
  font-weight: 200;
`
