import React from 'react'
import PropTypes from 'prop-types'
import {
  InfoBannerMainContainer,
  GreenBadge,
  BackgroundContainer,
  TextContent,
  TitleStyled,
  BodyStyled,
  IconsContainer,
  IconItemContainer,
  IconImage,
  IconLabel,
} from './styles'

const InfoBannerComponent = ({ query }) => {
  return (
    <BackgroundContainer>
      <InfoBannerMainContainer>
        {query?.logo?.localFile?.childImageSharp?.fluid ? (
          <GreenBadge
            fluid={query?.logo?.localFile?.childImageSharp?.fluid}
            alt={query?.logo?.alt}
          ></GreenBadge>
        ) : (
          <></>
        )}
        <TextContent>
          {query?.title?.text ? (
            <TitleStyled as="h3" type="h3">
              {query?.title?.text}
            </TitleStyled>
          ) : (
            <></>
          )}
          {query?.body?.text ? (
            <BodyStyled type="primary" as="p">
              {query?.body?.text}
            </BodyStyled>
          ) : (
            <></>
          )}
        </TextContent>
        {query?.icons?.length > 0 ? 
        (<IconsContainer>
          {query?.icons.map((icon, index) => {
              return (
                <IconItemContainer key={index}>
                  {icon?.icon_image?.localFile?.childImageSharp?.fluid ? (
                    <IconImage
                      fluid={
                        icon?.icon_image?.localFile?.childImageSharp?.fluid
                      }
                      alt={icon?.icon_image?.alt}
                    ></IconImage>
                  ) : (
                    <></>
                  )}
                  {icon?.icon_label?.text ? (
                    <IconLabel type="primary" as="p">
                      {icon?.icon_label?.text}
                    </IconLabel>
                  ) : (
                    <></>
                  )}
                </IconItemContainer>
              )
            })}
        </IconsContainer>) : <></>}
      </InfoBannerMainContainer>
    </BackgroundContainer>
  )
}

InfoBannerComponent.propTypes = {
  query: PropTypes.object.isRequired
}

export default InfoBannerComponent
