import React from 'react'
import PropTypes from 'prop-types'

const NetZeroGraphics = () => {
  return (
    <div>
      
    </div>
  )
}

NetZeroGraphics.propTypes = {

}

export default NetZeroGraphics
