import styled from '@emotion/styled'

export const GenericSectionStyled = styled.div`
  background: transparent;
  padding-top: ${props => (props.simpleLayoutDesign ? '80px' : '115px')};
  padding-bottom: ${props => (props.lastElement ? '70px' : 'initial')};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding-top: ${props => (props.noPrimaryTitle ? '0px' : '100px')};
    padding-bottom: ${props => (props.lastElement ? '0' : 'initial')};
  }
`
