import React from 'react'
import PropTypes from 'prop-types'
import {
  IconContainerMain,
  IconsContainerGridItem,
  IconImage,
  IconTitleStyled,
  IconBodyStyled,
  IconContent,
  FullWidthGraphics,
} from './styles'

const NetZeroIcons = ({ query }) => {
  return (
    <IconContainerMain className="IconContainerMain">
      {query?.items?.length > 0 &&
        query?.items.map((icon, index) => {
          return (
            <div key={index}>
              <IconsContainerGridItem>
                {icon?.icon_title?.text && icon?.icon_body?.text ? (
                  <>
                    <IconImage
                      fluid={
                        icon?.icon_image?.localFile?.childImageSharp?.fluid
                      }
                      alt={icon?.icon_image?.alt}
                    />
                    <IconContent>
                      <IconTitleStyled as="h4" type="h4">
                        {icon?.icon_title?.text}
                      </IconTitleStyled>
                      <IconBodyStyled as="p" type="primary">
                        {icon?.icon_body?.text}
                      </IconBodyStyled>
                    </IconContent>
                  </>
                ) : (
                  <></>
                )}
                {!icon?.icon_title?.text && !icon?.icon_body?.text ? (
                  <FullWidthGraphics
                    fluid={icon?.icon_image?.localFile?.childImageSharp?.fluid}
                    alt={icon?.icon_image?.alt}
                  />
                ) : (
                  <></>
                )}
              </IconsContainerGridItem>
            </div>
          )
        })}
    </IconContainerMain>
  )
}

NetZeroIcons.propTypes = {
  query: PropTypes.object.isRequired,
}

export default NetZeroIcons
