import React, { useContext, useEffect } from 'react'
import LocaleContext from 'context/LocaleProvider'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import NetZeroLayout from 'components/PagesLayout/NetZeroLayout'
import { withPreview } from 'gatsby-source-prismic'

const NetZero = props => {
  const lang = useContext(LocaleContext)
  const { data, location } = props
  const heroQuery =
    data?.allPrismicNetzeroPage?.nodes?.[0]?.data?.hero_component
  const infoBannerQuery =
    data?.allPrismicNetzeroPage?.nodes?.[0]?.data?.info_banner
  const preFooterQuery =
    data?.allPrismicNetzeroPage?.nodes?.[0]?.data?.prefooter_component
  const projectHighlightQuery =
    data?.allPrismicNetzeroPage?.nodes?.[0]?.data?.project_banner
  const sliceZoneQuery = data?.allPrismicNetzeroPage?.nodes?.[0]?.data?.body
  const globalQuery = data?.allPrismicGlobalContent?.nodes?.[0]?.data

  useEffect(() => {
    lang?.setPageDetails({
      uid: props?.pageContext?.alternativeLanguageUid,
      type: 'page',
    })
  }, [])

  const netZeroQuery = {
    heroQuery,
    infoBannerQuery,
    preFooterQuery,
    projectHighlightQuery,
    sliceZoneQuery,
    globalQuery,
  }

  return <NetZeroLayout {...netZeroQuery} location={location} />
}

NetZero.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default withPreview(NetZero)

export const netZeroPageQuery = graphql`
  query netZeroQuery($locale: String!) {
    allPrismicGlobalContent(filter: { lang: { eq: $locale } }) {
      nodes {
        data {
          scroll_down_label {
            text
          }
          read_more {
            text
          }
        }
      }
    }
    allPrismicNetzeroPage(filter: { lang: { eq: $locale } }) {
      nodes {
        lang
        data {
          seo_component {
            document {
              __typename
              ... on PrismicSeo {
                id
                data {
                  breadcrumb_title {
                    text
                  }
                  open_graph_title {
                    text
                  }
                  open_graph_description {
                    text
                  }
                  open_graph_image {
                    alt
                    localFile {
                      url
                    }
                  }
                  seo_title {
                    text
                  }
                  seo_description {
                    text
                  }
                }
              }
            }
          }
          hero_component {
            document {
              ... on PrismicHeroComponent {
                id
                data {
                  full_screen_height
                  title {
                    text
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  title_style
                  video {
                    url
                  }
                  body {
                    text
                  }
                }
              }
            }
          }
          info_banner {
            document {
              ... on PrismicGreenInfoBanner {
                id
                data {
                  title {
                    text
                  }
                  logo {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  icons {
                    icon_label {
                      text
                    }
                    icon_image {
                      dimensions {
                        height
                        width
                      }
                      alt
                      localFile {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_noBase64
                          }
                        }
                      }
                    }
                  }
                  body {
                    text
                  }
                }
              }
            }
          }
          project_banner {
            document {
              ... on PrismicFullwidthProjectHighlight {
                id
                data {
                  cta_styling
                  title {
                    text
                  }
                  fullwidth_background_image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  cta_link {
                    uid
                  }
                  cta_label {
                    text
                  }
                  page_type
                  body {
                    text
                  }
                }
              }
            }
          }
          prefooter_component {
            document {
              ... on PrismicPrefooter {
                id
                data {
                  title {
                    text
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  cta {
                    page_type
                    cta_style
                    cta_link {
                      uid
                    }
                    cta_label {
                      text
                    }
                  }
                  body {
                    text
                  }
                }
              }
            }
          }
          body {
            ... on PrismicNetzeroPageBodyContentSectionWithIcons {
              id
              primary {
                content_section_with_icons {
                  document {
                    ... on PrismicBodyContentSectionWithIcons {
                      id
                      data {
                        section_title {
                          html
                        }
                        section_image {
                          alt
                          localFile {
                            childImageSharp {
                              fluid {
                                ...GatsbyImageSharpFluid_noBase64
                              }
                            }
                          }
                        }
                        section_body {
                          html
                        }
                        page_type
                        icons {
                          icon_image {
                            dimensions {
                              height
                              width
                            }
                            alt
                            localFile {
                              childImageSharp {
                                fluid {
                                  ...GatsbyImageSharpFluid_noBase64
                                }
                              }
                            }
                          }
                          icon_description {
                            text
                          }
                        }
                        cta_link {
                          uid
                        }
                        cta_label {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicNetzeroPageBodyBasicComponent {
              id
              items {
                generic_component {
                  document {
                    ... on PrismicBasicContainer {
                      id
                      data {
                        heading_is_scrollable
                        font_weight_title_primary
                        font_weight_title_secondary
                        title_primary {
                          text
                        }
                        title_secondary {
                          text
                        }
                        style_type
                        line_decoration
                        body1 {
                          ... on PrismicBasicContainerBody1Text {
                            id
                            items {
                              text_title {
                                text
                              }
                              text_line_decoration
                              text_body {
                                html
                              }
                            }
                          }
                          ... on PrismicBasicContainerBody1List {
                            id
                            primary {
                              list_line_decoration
                            }
                            items {
                              list {
                                text
                              }
                            }
                          }
                          ... on PrismicBasicContainerBody1Image {
                            id
                            items {
                              desktop_add_spacing_right
                              desktop_touch_the_right_edge
                              image_line_decoration
                              mobile_add_spacing_around
                              mobile_add_spacing_right
                              image {
                                alt
                                localFile {
                                  childImageSharp {
                                    fluid {
                                      ...GatsbyImageSharpFluid_noBase64
                                    }
                                  }
                                }
                              }
                            }
                          }
                          ... on PrismicBasicContainerBody1Cta {
                            id
                            items {
                              page_type
                              cta_styling
                              cta_link {
                                uid
                              }
                              cta_label {
                                text
                              }
                            }
                          }
                          ... on PrismicBasicContainerBody1Icons {
                            id
                            items {
                              icon_title {
                                text
                              }
                              icon_text {
                                text
                              }
                              icon_image {
                                dimensions {
                                  height
                                  width
                                }
                                alt
                                localFile {
                                  childImageSharp {
                                    fluid {
                                      ...GatsbyImageSharpFluid_noBase64
                                    }
                                  }
                                }
                              }
                            }
                          }
                          ... on PrismicBasicContainerBody1Logo {
                            id
                            items {
                              logo {
                                alt
                                localFile {
                                  childImageSharp {
                                    fluid {
                                      ...GatsbyImageSharpFluid_noBase64
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        component_position
                        body {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicNetzeroPageBodyContentSections {
              id
              items {
                section_title {
                  text
                }
                section_image {
                  alt
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                section_body {
                  html
                }
                page_type
                image_alignment
                cta_styling
                cta_link {
                  uid
                }
                cta_label {
                  text
                }
              }
            }
            ... on PrismicNetzeroPageBodyFullWidthImage {
              id
              items {
                fullwidth_image {
                  alt
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicNetzeroPageBodyGraphics {
              id
              items {
                graphics {
                  alt
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicNetzeroPageBodyIcons {
              id
              items {
                icon_title {
                  text
                }
                icon_image {
                  dimensions {
                    height
                    width
                  }
                  alt
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                icon_body {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`
