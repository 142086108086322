import React from 'react'
import PropTypes from 'prop-types'
import IntersectionVisible from 'react-intersection-visible'
import { getQuery } from 'functions/getQuery'
import StickyHeader from 'components/StickyHeader'
import GenericSectionBody from 'components/GenericSectionBody'
import { GenericSectionStyled } from './styles'

const GenericSection = props => {
  const {
    query,
    lastElement,
    simpleLayoutDesign,
    isFirstElement,
    withFixedHeading,
    className,
    index,
    styleContext,
  } = props

  const genericSectionQuery = getQuery(
    ['generic_component', 'document', 'data'],
    query
  )

  const genericSectionBodyQuery = getQuery(
    ['generic_component', 'document', 'data', 'body1'],
    query
  )

  const componentStyle = genericSectionQuery?.style_type
  const componentPosition = genericSectionQuery?.component_position
  const noPrimaryTitle = !genericSectionQuery?.title_primary?.text
  const stickyBody = genericSectionQuery?.body?.text?.length > 0

  const observerProps = {
    onShow: () => {
      if (withFixedHeading) {
        styleContext?.setCurrentSectionVisible(index)
      }
      styleContext?.setStyleActivated(componentStyle)
    },
    options: {
      rootMargin: '-80px 0px -80px 0px',
    },
  }

  return (
    <>
      <GenericSectionStyled
        lastElement={lastElement}
        noPrimaryTitle={noPrimaryTitle}
        simpleLayoutDesign={simpleLayoutDesign}
        className={`${className} GenericSectionStyled`}
      >
        {!withFixedHeading ? (
          <StickyHeader
            query={genericSectionQuery}
            lastElement={lastElement}
            simpleLayoutDesign={simpleLayoutDesign}
            isFirstElement={isFirstElement}
          />
        ) : (
          <></>
        )}
        {genericSectionBodyQuery ? (
          <IntersectionVisible {...observerProps}>
            <GenericSectionBody
              query={genericSectionBodyQuery}
              componentPosition={componentPosition}
              noPrimaryTitle={noPrimaryTitle}
              stickyBody={stickyBody}
              simpleLayoutDesign={simpleLayoutDesign}
              index={index}
              componentStyle={componentStyle}
              styleContext={styleContext}
            />
          </IntersectionVisible>
        ) : (
          <></>
        )}
      </GenericSectionStyled>
    </>
  )
}

GenericSection.propTypes = {
  query: PropTypes.object.isRequired,
  lastElement: PropTypes.bool,
  styleContext: PropTypes.object,
  simpleLayoutDesign: PropTypes.bool,
  isFirstElement: PropTypes.bool,
  withFixedHeading: PropTypes.bool,
  className: PropTypes.string,
  index: PropTypes.number,
  styleContext: PropTypes.object,
}

export default GenericSection
