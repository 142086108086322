import React from 'react'
import PropTypes from 'prop-types'
import NetZeroBasicComponent from './NetZeroSliceComponents/NetZeroBasicComponent'
import NetZeroBodyContentSection from './NetZeroSliceComponents/NetZeroBodyContentSection'
import NetZeroIcons from './NetZeroSliceComponents/NetZeroIcons'
import NetZeroGraphics from './NetZeroSliceComponents/NetZeroGraphics'
import NetZeroFullWidthImage from './NetZeroSliceComponents/NetZeroFullWidthImage'
import ContentWithIcons from 'components/ContentWithIcons'
import { NetZeroSlices } from './styles'

const NetZeroSliceZone = ({ query, globalQuery, locale }) => {
  const slice = query.map((slice, index) => {
    switch (slice.__typename) {
      // These are the API IDs of the slices
      case 'PrismicNetzeroPageBodyBasicComponent':
        return (
          <NetZeroBasicComponent
            key={`${slice.id}--${index}`}
            query={slice}
            globalQuery={globalQuery}
            locale={locale}
          />
        )
      case 'PrismicNetzeroPageBodyContentSections':
        return (
          <NetZeroBodyContentSection
            key={`${slice.id}--${index}`}
            query={slice}
          />
        )
      case 'PrismicNetzeroPageBodyIcons':
        return <NetZeroIcons key={`${slice.id}--${index}`} query={slice} />
      case 'PrismicNetzeroPageBodyFullWidthImage':
        return (
          <NetZeroFullWidthImage key={`${slice.id}--${index}`} query={slice} />
        )
      case 'PrismicNetzeroPageBodyGraphics':
        return <NetZeroGraphics key={`${slice.id}--${index}`} query={slice} />
      case 'PrismicNetzeroPageBodyContentSectionWithIcons':
        return <ContentWithIcons key={`${slice.id}--${index}`} data={slice} />
      default:
        return null
    }
  })

  return <NetZeroSlices>{slice}</NetZeroSlices>
}

NetZeroSliceZone.propTypes = {
  query: PropTypes.array.isRequired,
}

export default NetZeroSliceZone
