import styled from '@emotion/styled'

export const NetZeroLayoutContainer = styled.div`
  .Wrapper__NetZero-WidthLimiter {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding-top: 150px;
      max-width: 2000px;
      margin: 0 auto;
    }
  }

  .Prefooter__NetZero {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-top: 0px;
    }
  }

  .NetZero__GenericComponent {
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding-top: 80px;
      padding-bottom: 0px;
    }
  }
`
