import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { getQuery } from 'functions/getQuery'
import GenericSection from 'components/GenericSection'
import StyleContext from 'context/StyleProvider'

const NetZeroBasicComponent = ({ query, globalQuery, locale }) => {
  const genericComponentQuery = getQuery(['items'], query)
  const styleContext = useContext(StyleContext)

  return (
    <>
      {genericComponentQuery?.length > 0 ? (
        genericComponentQuery?.map((section, index) => {
          const isLastElement = index === genericComponentQuery?.length - 1
          return (
            <GenericSection
              key={index}
              query={section}
              lastElement={isLastElement}
              locale={locale}
              styleContext={styleContext}
              globalQuery={globalQuery}
              className="NetZero__GenericComponent"
              simpleLayoutDesign
            />
          )
        })
      ) : (
        <></>
      )}
    </>
  )
}

NetZeroBasicComponent.propTypes = {
  query: PropTypes.object.isRequired,
  globalQuery: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
}

export default NetZeroBasicComponent
