import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import {
  BodyContentContainer,
  MainContentContainer,
  PrimaryContainer,
  StyledTitle,
  StyledBody,
  SecondaryContainer,
  StyledImageContent,
  CtaStyled,
} from './styles'

const NetZeroBodyContentSection = ({ query }) => {
  return (
    <Fade distance="30px" bottom>
      <BodyContentContainer className="BodyContentContainer">
        {query?.items?.length > 0 &&
          query?.items?.map((section, index) => {
            const alignImageLeft = section.image_alignment === 'left'
            return (
              <MainContentContainer alignImageLeft={alignImageLeft} key={index}>
                <PrimaryContainer alignImageLeft={alignImageLeft}>
                  {section?.section_title?.text ? (
                    <Fade distance="30px" bottom>
                      <StyledTitle type="h3" as="h3">
                        {section.section_title.text}
                      </StyledTitle>
                    </Fade>
                  ) : (
                    <></>
                  )}
                  {section?.section_body?.html ? (
                    <Fade distance="30px" bottom>
                      <StyledBody
                        type="primary"
                        dangerouslySetInnerHTML={{
                          __html: section.section_body.html,
                        }}
                      />
                    </Fade>
                  ) : (
                    <></>
                  )}
                  {section?.cta_link?.uid && section?.cta_label?.text ? (
                    <Fade distance="30px" bottom>
                      <CtaStyled
                        to={section?.cta_link?.uid}
                        pageType={section?.page_type}
                      >
                        {section?.cta_label?.text}
                      </CtaStyled>
                    </Fade>
                  ) : (
                    <></>
                  )}
                </PrimaryContainer>
                <SecondaryContainer alignImageLeft={alignImageLeft}>
                  {section?.section_image?.localFile?.childImageSharp?.fluid ? (
                    <Fade distance="30px" bottom>
                      <StyledImageContent
                        fluid={
                          section?.section_image?.localFile?.childImageSharp
                            ?.fluid
                        }
                        alt={section?.section_image?.alt}
                      ></StyledImageContent>
                    </Fade>
                  ) : (
                    <></>
                  )}
                </SecondaryContainer>
              </MainContentContainer>
            )
          })}
      </BodyContentContainer>
    </Fade>
  )
}

NetZeroBodyContentSection.propTypes = {
  query: PropTypes.object.isRequired,
}

export default NetZeroBodyContentSection
