import styled from '@emotion/styled'
import Img from 'gatsby-image'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'

export const BackgroundContainer = styled.div`
  background-color: ${props => props.theme.colors.lightblue};
  width: 100%;
`

export const InfoBannerMainContainer = styled.div`
  max-width: calc(100% - 20px);
  margin-right: 20px;
  margin-left: 20px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 2000px;
    width: calc(100% - 100px);
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 0px;
    padding-bottom: 150px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    width: calc(100% - 200px);
    margin-left: 100px;
    margin-right: 100px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    width: calc(100% - 376px);
    margin-left: 186px;
    margin-right: 186px;
  }
`

export const GreenBadge = styled(Img)`
  position: absolute;
  width: 100px;
  height: 110px;
  top: 0;
  left: 0px;
`

export const TextContent = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-around;
    padding-top: 170px;
    padding-bottom: 150px;
  }
`

export const TitleStyled = styled(Title)`
  font-weight: 200;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 40px;
    font-weight: 500;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    margin-right: 20px;
  }
`

export const BodyStyled = styled(Text)`
  font-weight: 200;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 145px;
  }
`

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-right: 50px;
    justify-content: space-between;
    padding-bottom: initial;
    width: 100%;
    flex-wrap: wrap;
  }
`

export const IconItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 50%;
  margin-bottom: 30px;
`

export const IconImage = styled(Img)`
  min-width: 80px;
  width: 80px;
  height: auto;
  margin-right: 15px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-right: 30px;
  }
`

export const IconLabel = styled(Text)`
  font-weight: 200;
  white-space: pre-wrap;
`
