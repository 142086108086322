import styled from '@emotion/styled'
import Img from 'gatsby-image'

export const FullWidthImageContainer = styled.div`
  width: 100%;
  max-width: 2000px;
`

export const FullWidthImageStyled = styled(Img)`
  min-height: 200px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-height: 550px;
    margin-bottom: 150px;
  }
`
