import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import LocaleContext from 'context/LocaleProvider'
import Hero from 'components/Hero'
import Wrapper from 'components/Wrapper'
import NetZeroSliceZone from './NetZeroSliceZone'
import PreFooterComponent from 'components/PreFooter'
import InfoBannerComponent from 'components/InfoBanner'
import ProjectHighlightComponent from 'components/ProjectHighlight'
import { WrapperContent } from 'components/Wrapper/styles.js'
import { NetZeroLayoutContainer } from './styles'

const NetZeroLayout = props => {
  const lang = useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const {
    heroQuery,
    infoBannerQuery,
    preFooterQuery,
    projectHighlightQuery,
    sliceZoneQuery,
    globalQuery,
  } = props

  const heroProps = { ...heroQuery?.document?.data, ...globalQuery }

  return (
    <NetZeroLayoutContainer>
      <Hero {...heroProps} />
      <Wrapper hasFullHeight={heroQuery?.full_screen_height}>
        <WrapperContent className="Wrapper__NetZero-WidthLimiter">
          {sliceZoneQuery && globalQuery && i18n.locale ? (
            <NetZeroSliceZone
              query={sliceZoneQuery}
              globalQuery={globalQuery}
              locale={i18n.locale}
            />
          ) : (
            <></>
          )}
          {infoBannerQuery?.document?.data ? (
            <InfoBannerComponent query={infoBannerQuery?.document?.data} />
          ) : (
            <></>
          )}
          {projectHighlightQuery?.document?.data ? (
            <ProjectHighlightComponent
              query={projectHighlightQuery?.document?.data}
            />
          ) : (
            <></>
          )}
          {preFooterQuery?.document?.data ? (
            <PreFooterComponent
              className="Prefooter__NetZero"
              query={preFooterQuery?.document?.data}
            />
          ) : (
            <></>
          )}
        </WrapperContent>
      </Wrapper>
    </NetZeroLayoutContainer>
  )
}

NetZeroLayout.propTypes = {
  heroQuery: PropTypes.object.isRequired,
  infoBannerQuery: PropTypes.object.isRequired,
  preFooterQuery: PropTypes.object.isRequired,
  projectHighlightQuery: PropTypes.object.isRequired,
  sliceZoneQuery: PropTypes.array.isRequired,
  globalQuery: PropTypes.object.isRequired,
}

export default NetZeroLayout
