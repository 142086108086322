import styled from '@emotion/styled'

export const NetZeroSlices = styled.div`
  display: block;
  height: auto;

  .StickyHeader__AnimatedLine {
    @media(max-width: ${props => props.theme.breakpoints.md}) {
      bottom: 100%!important;
      position: absolute;
      right: 0;
    }
  }

  .GenericSectionSliceTextContainer {
    @media(max-width: ${props => props.theme.breakpoints.md}) {
      padding-top: 0px!important;
    }
  }
`
